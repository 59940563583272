* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.xfx{
  background-color: #F0CAC9;
  background-image: 
      linear-gradient(90deg, #F7776A 7px, transparent 0),
      linear-gradient(90deg, #8FA6C6 23px, transparent 0),
      linear-gradient(90deg, #034F83 23px, transparent 0);
  background-size: 43px 100%, 61px 100%, 89px 100%;
}
.xff {
  --s: 80px; /* control the size */
  
  --c:#6B5344 0;
  --g:conic-gradient(at 50% 25%,#0000 75%,var(--c));
  background:
    repeating-linear-gradient(#0000 0 48%,var(--c) 50%),var(--g),
    conic-gradient(#0000 75%,var(--c)) calc(var(--s)/4) calc(var(--s)/2),
    var(--g) calc(var(--s)/2) var(--s) #F8ECC9;
  background-size: var(--s) var(--s),var(--s) calc(2*var(--s));
}